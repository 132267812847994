import React from 'react'
import styled, { css } from 'styled-components'

const RATIOS_TO_PADDING = {
  '16:9': '56.25%',
  '4:3': '75%',
  '3:2': '66.66%',
  '8:5': '62.5%',
  // portrait video
  '9:16': '177.77%',
}
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  ${(props: Props) => css`
    padding-bottom: ${RATIOS_TO_PADDING[props.ratio]};
  `}
`

const Content = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

interface Props {
  ratio: '16:9' | '4:3' | '3:2' | '8:5' | '9:16'
  children: any;
}

export default (props: Props) => {
  return <Wrapper ratio={props.ratio}>
    <Content>{props.children}</Content>
  </Wrapper>
}
