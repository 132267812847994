import VimeoEmbed from 'components/VimeoEmbed'
import { Button, Close, IconButton, Tick } from 'nzk-react-components'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  z-index: 21;
  background-color: #341644 !important;
  border-radius: 20px;
  width: 90%;
  max-width: 320px;
  position: fixed;
  left: 50%;
  top: 50%;
  box-shadow: 0 -5px 0 #531D75, 0 10px 0 #1C042B, 0 14px 0 rgba(0,0,0,0.3);
  transform: translateX(-50%) translateY(-50%);
  @media screen and (max-height: 760px) {
    max-width: 280px;
  }
  @media screen and (max-height: 700px) {
    max-width: 250px;
  }
`

const Content = styled.div`
  z-index: 2;
  width: 100%;
  padding: 30px 20px 40px 20px;
  border-radius: 20px;
  background-color: #341644;
  display: flex;
  color: white;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  p {
    font-family: 'Libre Baskerville';
    font-size: 16px;
  }
  a {
    text-decoration: underline;
  }
  .title {
    font-family: 'Rammetto One';
    font-size: 16px;
    margin-bottom: 10px;
    text-align: center;
    text-shadow: 0px 2px rgba(0,0,0,.5);
  } 

   @media screen and (max-height: 630px) {
    p, .title {
      display: none;
    }
  }
`

const Actions = styled.div`
  position: absolute;
  bottom: -30px;
  display: flex;
  justify-content: space-evenly;
  gap: 40px;
`

const Delete = styled.div`
  position: absolute;
  right: -20px;
  top: -20px;
  z-index: 2;
`


interface Props {
  onClose: () => void
}

const VIDEO_ID = '1039714519'

const ParentReviewsPopup = (props: Props) => {
  return <Wrapper>
    <Content>
      <div className='title'>Hear what other parents have to say</div>
      <VimeoEmbed vimeoId={VIDEO_ID} ratio='9:16' />
      <p><a target='_blank' href="https://www.nightzookeeper.com/reviews">Read more testimonials</a></p>
      <Actions>
        <IconButton icon={<Tick />} theme='confirm' onClick={props.onClose} size='regular'>Continue</IconButton>
      </Actions>
    </Content>
    <Delete>
      <Button theme="red" size="regular" round onClick={props.onClose}>
        <Close />
      </Button>
    </Delete>
  </Wrapper>
}

export default ParentReviewsPopup
