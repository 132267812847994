import React, { useEffect, useState } from "react"
import ParentReviewsPopup from "."
import { useCurrentUserState } from "state/CurrentUserState"

export const useParentReviews = (props: {
  canShow: () => boolean
  onDisplayed?: () => void
}) => { 
  const [shouldShow, setShouldShow] = useState(false)
  const { clearTutorialStep, currentUser } = useCurrentUserState()

  const onClose = () => {
    clearTutorialStep('parentReviews')
    setShouldShow(false)
  }

  useEffect(() => {
    if (props.canShow()) {
      const subscriptionType = currentUser?.subscription?.type
      const hasSeenParentReviews = currentUser?.tutorial?.parentReviews
      const createdAt = currentUser?.createdAt ? new Date(currentUser.createdAt) : null
      const createdAtLessThanADayAgo = createdAt && new Date().getTime() - createdAt.getTime() < 1000 * 60 * 60 * 12 // 12h
      const shouldSeeWelcomeVideo = localStorage.getItem('should-show-welcome-video')  
      const seenWelcomeVideoAt = localStorage.getItem('seen-welcome-video-at') ? new Date(localStorage.getItem('seen-welcome-video-at') as string) : null
      const seenWelcomeVideoAtLessThanADayAgo = seenWelcomeVideoAt && new Date().getTime() - seenWelcomeVideoAt.getTime() < 1000 * 60 * 60 * 12 // 12h

      if (subscriptionType === 'kid-first') return
      if (hasSeenParentReviews) return
      if (createdAtLessThanADayAgo) return
      if (shouldSeeWelcomeVideo) return 
      if (seenWelcomeVideoAt && seenWelcomeVideoAtLessThanADayAgo) return 
  
      setShouldShow(true)

      if (props.onDisplayed) {
        props.onDisplayed()
      }
    }
  }, [currentUser])

  return { 
    shouldShow,
    modal: shouldShow ? <ParentReviewsPopup onClose={onClose} /> : null,
  }
}